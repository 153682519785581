<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <div
        style="display: flex; gap: 10px; flex-wrap: wrap; align-items: center"
      >
        <a-input
          placeholder="订单号"
          v-model="queryParam.no"
          style="width: 10%; margin-left: 8px"
        />
        <a-input
          placeholder="备注"
          v-model="queryParam.comment"
          style="width: 10%; margin-left: 8px; "
        />
        <a-select
          placeholder="状态"
          :allowClear="true"
          v-model.trim="queryParam.status"
          style="width: 10%; margin-left: 8px"
        >
          <a-select-option
            v-for="array in orderStatusArray"
            :key="array.value"
            >{{ array.name }}</a-select-option
          >
        </a-select>
        <a-select
          placeholder="商品"
          :allowClear="true"
          v-model.trim="queryParam.goodsId"
          style="width: 10%; margin-left: 8px;"
        >
          <a-select-option v-for="array in goodsList" :key="array.id">{{
            array.name
          }}</a-select-option>
        </a-select>
        <a-select
          placeholder="平台"
          :allowClear="true"
          v-model.trim="queryParam.platformType"
          style="width: 10%; margin-left: 8px"
        >
          <a-select-option
            v-for="array in platformTypeArray"
            :key="array.value"
            >{{ array.name }}</a-select-option
          >
        </a-select>
        <a-select
          placeholder="下载模板"
          :allowClear="true"
          v-model.trim="downloadType.selected"
          style="width: 10%; margin-left: 8px"
        >
          <a-select-option
            v-for="array in downloadType.types"
            :key="array.value"
            >{{ array.name }}</a-select-option
          >
        </a-select>
        <a-range-picker
          style="width: 28%;margin-left: 8px"
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          :defaultValue="[
            moment(queryParam.payTimeStart),
            moment(queryParam.payTimeEnd)
          ]"
          @change="onChangeDatePay"
        />
        <a-button
          style="margin-left: 8px"
          class="editable-add-btn"
          type="primary"
          @click="$refs.table.refresh(false)"
          >查询</a-button
        >
        <a
          href="http://static.wodh.cn/lpw/%E5%8F%91%E8%B4%A7%E6%A8%A1%E6%9D%BF.xlsx"
          style="margin-left: 8px;"
          >代发常用模板</a
        >
        <a-upload
          class="top5"
          :multiple="false"
          :showUploadList="false"
          :customRequest="customRequest"
          :disabled="upload"
          accept=".xls, .xlsx"
        >
          <a-button
            :loading="upload"
            style="margin-left: 8px;"
            class="editable-add-btn"
            >上传excel</a-button
          >
        </a-upload>
        <a style="margin-left: 8px" @click="downloadAll"
          >一键下载所有进行中的订单</a
        >
        <ul class="time-select">
          <li
            v-for="item in shortcutDate"
            :key="item.id"
            @click="handleShortcutDate(item)"
            :class="currentShortcutDate === item.date ? 'active' : ''"
          >
            {{ item.title }}
          </li>
        </ul>
        <a-button
          style="margin-left: 8px"
          class="editable-add-btn"
          @click="loadAllOrder"
          >下载1</a-button
        >
        <a-button
          style="margin-left: 8px"
          class="editable-add-btn"
          @click="loadAllOrderDelivery"
          >下载2</a-button
        >
        <a-button
          style="margin-left: 8px"
          class="editable-add-btn"
          @click="orderPlatformAll"
          >一键重推</a-button
        >
        <a-select
          placeholder="订单类型"
          v-model="orderType"
          style="width: 10%; margin-left: 8px"
          :options="[
            { label: '问题订单', value: 'error' },
            { label: '全部订单', value: 'all' }
          ]"
          @change="orderTypeChange"
        />
      </div>
      <a-row :gutter="48">
        <a-col :md="4" :sm="24">
          <a-form-item label="下单用户数">
            <span style="font-size: 24px;">{{ orderCountTotal.uid }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="4" :sm="24">
          <a-form-item label="总子订单数">
            <span style="font-size: 24px;">{{ orderCountTotal.count }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="4" :sm="24">
          <a-form-item label="总收款">
            <span style="font-size: 24px;">{{
              orderCountTotal.incomeActual
            }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="4" :sm="24">
          <a-form-item label="总溢价">
            <span style="font-size: 24px;">{{
              orderCountTotal.premiumPrice
            }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <s-table
        style="margin-top: 10px"
        ref="table"
        size="small"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
      >
        <a-table
          rowKey="id"
          slot="expandedRowRender"
          size="small"
          slot-scope="record"
          :columns="innerColumns"
          :scroll="{ x: 1500 }"
          :dataSource="record.details"
          :pagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">{{
            index + 1
          }}</span>
          <template slot="orderNo" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>

              <span
                class="uid_fat"
                @click="copyInfo(record.orderNo)"
                @mouseover="handleMouseover(record)"
                @mouseout="handleMouseout(record)"
              >
                {{
                  text.substring(0, 4) +
                    "……" +
                    text.substring(text.split("").length - 4)
                }}
                <span class="uid_child" v-show="record.show">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <template
            v-for="col in ['waybillCompany', 'waybillNo']"
            :slot="col"
            slot-scope="text, detail, index"
          >
            <div :key="col">
              <a-input
                v-if="detail.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, detail, col)"
              />
              <template v-else>{{ text }}</template>
            </div>
          </template>
          <template slot="operation" slot-scope="text, detail">
            <a style="margin-right: 8px;" @click="modify(detail)">修改</a>
            <span v-if="detail.editable">
              <a @click="() => save(detail, record)">保存</a>
              <a @click="() => cancel(detail)" style="margin-left: 8px;"
                >取消</a
              >
            </span>

            <a
              v-else-if="record.amount !== 0 && detail.incomeActual !== 0"
              :disabled="originalData !== ''"
              @click="edit(detail)"
              >发货</a
            >

            <a-popconfirm
              title="是否关闭?（不能恢复）"
              @confirm="() => closeDetail(detail.id)"
            >
              <a
                href="javascript:"
                v-if="detail.amount !== 0"
                style="margin-left: 5px"
                >关闭</a
              >
            </a-popconfirm>
          </template>
        </a-table>
        <span slot="serial" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <template slot="picture" slot-scope="text">
          <img
            :src="text"
            @click="() => (preview = { show: true, image: text })"
            style="width: 45px;height: 45px;"
          />
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <a @click="toExcel(record)">下载</a>
          <span v-if="record.status === 'IN'">
            <a
              style="margin-left: 5px;"
              @click="abnormalEdit(record, 'ABNORMAL')"
              >设为异常订单</a
            >
            <a style="margin-left: 5px;" @click="orderPlatformTo(record.id)"
              >重推API</a
            >
            <a style="margin-left: 5px;" @click="modifyManyMobile(record.id)"
              >修改整单手机号</a
            >
          </span>
          <a
            style="margin-left: 5px;"
            v-if="record.status === 'SUCCESS'"
            @click="saveFast(record)"
            >一键发货</a
          >
          <a
            style="margin-left: 5px;"
            v-if="record.status === 'ABNORMAL'"
            @click="abnormalEdit(record, 'IN')"
            >取消异常订单</a
          >
          <!-- <a-popconfirm
            title="是否关闭?（不能恢复）"
            @confirm="() => close(record.id)"
          >
            <a
              href="javascript:"
              v-if="record.amount !== 0 && record.status !== 'SUCCESS'"
              style="margin-left: 5px"
              >关闭</a
            >
          </a-popconfirm> -->
          <a-popconfirm
            title="是否完成订单?（不能恢复）"
            @confirm="() => complete(record)"
          >
            <a
              href="javascript:"
              v-if="record.amount !== 0 && record.status !== 'SUCCESS'"
              style="margin-left: 5px"
              >完成</a
            >
          </a-popconfirm>
        </template>
      </s-table>
      <a-modal
        :visible="preview.show"
        :footer="null"
        @cancel="() => (preview.show = false)"
      >
        <img style="width: 100%" :src="preview.image" />
      </a-modal>
      <a-modal
        title="提交异常"
        :width="640"
        :visible="abnormal.show"
        :maskClosable="false"
        @cancel="abnormalCancel"
        @ok="abnormalOk"
      >
        <a-input
          class="top5"
          placeholder="异常描述"
          v-model="abnormal.abnormal"
        />
      </a-modal>
      <a-modal
        title="修改"
        :width="480"
        :visible="modifyManyMobileVisible"
        :maskClosable="false"
        @cancel="modifyManyMobileCancel"
        @ok="modifyManyMobileOk"
      >
        <div class="input_group">
          <span>手机号：</span>
          <a-input
            class="modity_input"
            placeholder="手机号"
            v-model="modifyData.mobile"
          />
        </div>
      </a-modal>

      <a-modal
        title="修改"
        :width="480"
        :visible="modifyVisible"
        :maskClosable="false"
        @cancel="modifyCancel"
        @ok="modifyOk"
      >
        <div class="input_group">
          <span>姓名：</span>
          <a-input
            class="modity_input"
            placeholder="姓名"
            v-model="modifyData.name"
          />
        </div>
        <div class="input_group">
          <span>手机号：</span>
          <a-input
            class="modity_input"
            placeholder="手机号"
            v-model="modifyData.mobile"
          />
        </div>
        <div class="input_group">
          <span>省：</span>
          <a-input
            class="modity_input"
            placeholder="省"
            v-model="modifyData.province"
          />
        </div>
        <div class="input_group">
          <span>市：</span>
          <a-input
            class="modity_input"
            placeholder="市"
            v-model="modifyData.city"
          />
        </div>
        <div class="input_group">
          <span>县：</span>
          <a-input
            class="modity_input"
            placeholder="县"
            v-model="modifyData.county"
          />
        </div>
        <div class="input_group">
          <span>地址详情：</span>
          <a-input
            class="modity_input"
            placeholder="地址详情"
            v-model="modifyData.address"
          />
        </div>
      </a-modal>
    </a-card>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  orderList,
  orderDetailSend,
  orderDetailSendMany,
  orderUpdate,
  orderCount,
  orderClose,
  orderDetailClose,
  goodsListAll,
  orderPlatformToId,
  orderAllDetail,
  orderDetailUpdate,
  orderSendOne
} from "@api";
import { export_json_to_excel } from "@/utils/excel/Export2Excel";
import {
  formatDate,
  orderStatus,
  jsonToArray,
  sysTime,
  platformType
} from "@/utils/util";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default {
  data() {
    return {
      modifyManyMobileVisible: false,
      modifyVisible: false,
      modifyData: {},
      downloadType: {
        types: [
          { value: "1", name: "下载模式1" },
          { value: "2", name: "下载模式2" }
        ],
        selected: "1"
      },
      goodsList: [],
      abnormal: { show: false },
      preview: { image: "", show: false },
      upload: false,
      originalData: "", // 编辑前的原始数据
      orderStatusArray: jsonToArray(orderStatus),
      platformTypeArray: jsonToArray(platformType),
      payModal: false,
      currentShortcutDate: 0,
      orderCountTotal: {},
      queryParam: {
        payTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        payTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      },
      orderType: "error",
      columns: [
        { title: "#", scopedSlots: { customRender: "serial" } },
        { title: "订单号", dataIndex: "no" },
        { title: "商品标题", dataIndex: "goods.name" },
        {
          title: "商品图片",
          dataIndex: "goods.picture",
          scopedSlots: { customRender: "picture" }
        },
        { title: "子订单数", dataIndex: "count" },
        { title: "子订单完成数", dataIndex: "complete" },
        {
          title: "平台",
          dataIndex: "platformType",
          customRender: text => platformType[text]
        },
        { title: "收入", dataIndex: "incomeActual" },
        { title: "溢价", dataIndex: "premiumPrice" },
        {
          title: "订单状态",
          dataIndex: "status",
          customRender: text => orderStatus[text]
        },
        { title: "备注", dataIndex: "comment" },
        { title: "付款时间", dataIndex: "payTime", customRender: formatDate },
        {
          title: "更新时间",
          dataIndex: "updateTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "createTime",
          scopedSlots: { customRender: "operation" }
        }
      ],
      orderData: [],
      dataSource: parameter => {
        return orderList(Object.assign(parameter, this.queryParam), 2).then(
          result => {
            result.data.list.forEach(item => {
              item.details.forEach(item2 => {
                item2.show = false;
              });
            });
            this.orderData = result.data.list;
            this.originalData = ""; // 设置原始数据为空
            orderCount(Object.assign(parameter, this.queryParam), 2).then(
              orderCountResult => {
                this.orderCountTotal = orderCountResult.data;
              }
            );
            if (this.orderType === "error") {
              result.data.list = result.data.list.filter(val => {
                const flag = val.details.some(i => !i.deliveryTime);
                if (flag)
                  val.details = val.details.sort(
                    (a, b) => a.deliveryTime - b.deliveryTime
                  );
                return flag;
              });
            }
            return result.data;
          }
        );
      },
      innerColumns: [
        { title: "#", scopedSlots: { customRender: "serial" } },
        { title: "订单id", dataIndex: "id" },
        {
          title: "订单号",
          dataIndex: "orderNo",
          scopedSlots: { customRender: "orderNo" }
        },
        { title: "姓名", dataIndex: "name" },
        { title: "手机号", dataIndex: "mobile" },
        { title: "省", dataIndex: "province" },
        { title: "市", dataIndex: "city" },
        { title: "县", dataIndex: "county" },
        { title: "地址详情", dataIndex: "address" },
        {
          title: "快递公司",
          dataIndex: "waybillCompany",
          scopedSlots: { customRender: "waybillCompany" }
        },
        {
          title: "快递编号",
          dataIndex: "waybillNo",
          scopedSlots: { customRender: "waybillNo" }
        },
        {
          title: "发货时间",
          dataIndex: "deliveryTime",
          customRender: formatDate
        },
        { title: "备注", dataIndex: "comment" },
        {
          title: "操作",
          dataIndex: "createTime",
          scopedSlots: { customRender: "operation" }
        }
      ],
      shortcutDate: [
        {
          id: 1,
          title: "今天",
          date: 0
        },
        {
          id: 2,
          title: "昨天",
          date: 1
        },
        {
          id: 3,
          title: "最近7天",
          date: 7
        },
        {
          id: 4,
          title: "最近30天",
          date: 30
        }
      ]
    };
  },
  created() {
    goodsListAll({ pageSize: 1000, uid: this.$store.getters.userInfo.id }).then(
      res => {
        this.goodsList = res.data.list;
      }
    );
  },
  components: {
    PageView,
    STable
  },
  methods: {
    moment,
    close(id) {
      orderClose("sell", id).then(res => {
        if (res.success) {
          this.$message.success("关闭成功");
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    orderTypeChange() {
      this.$refs.table.refresh(false);
    },
    handleMouseover(record) {
      record.show = true;
    },
    handleMouseout(record) {
      record.show = false;
    },

    orderPlatformTo(id) {
      orderPlatformToId(id).then(res => {
        if (res.success) {
          this.$message.success("触发成功");
          // this.$refs.table.refresh(false)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    orderPlatformAll() {
      this.orderData.forEach(item => {
        if (item.status == "IN") {
          this.orderPlatformTo(item.id);
        }
      });
    },
    closeDetail(id) {
      orderDetailClose("sell", id).then(res => {
        if (res.success) {
          this.$message.success("关闭成功");
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    abnormalEdit(record, status) {
      if (status === "IN") {
        orderUpdate(
          { id: record.id, abnormal: "", status: status },
          "statusBySell"
        ).then(res => {
          if (res.success) {
            record.status = status;
            record.abnormal = "";
            this.$message.success("保存成功");
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        record.show = true;
        const abnormal = JSON.parse(JSON.stringify(record));
        abnormal.status = status;
        this.abnormal = abnormal;
      }
    },
    complete(record) {
      orderUpdate({ id: record.id }, "complete").then(res => {
        if (res.success) {
          record.status = "SUCCESS";
          this.$message.success("完成订单成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    abnormalCancel() {
      this.abnormal = { show: false };
    },
    abnormalOk() {
      orderUpdate(this.abnormal, "statusBySell").then(res => {
        if (res.success) {
          this.$message.success("保存成功");
          this.abnormal = { show: false };
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    edit(detail) {
      this.originalData = JSON.stringify(detail);
      detail.editable = true;
    },
    modify(data) {
      this.modifyData = JSON.parse(JSON.stringify(data));
      this.modifyVisible = true;
    },
    modifyOk() {
      orderDetailUpdate(this.modifyData, "detailBySell").then(res => {
        if (res.success) {
          this.$message.success("修改成功");
          this.modifyVisible = false;
          // 刷新列表
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    modifyCancel() {
      this.modifyVisible = false;
    },
    modifyManyMobile(orderId) {
      // todo 批量修改手机号的时候，id设成 -的 避免请求被接口拦截
      this.modifyData = { id: -orderId, orderId: orderId, mobile: "" };
      this.modifyManyMobileVisible = true;
    },
    modifyManyMobileCancel() {
      this.modifyManyMobileVisible = false;
    },
    modifyManyMobileOk() {
      orderDetailUpdate(this.modifyData, "mobileMany").then(res => {
        if (res.success) {
          this.$message.success("修改整单手机号成功");
          this.modifyManyMobileVisible = false;
          // 一键重推
          this.orderPlatformTo(this.modifyData.orderId);
          // 刷新列表
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleChange(value, detail, column) {
      detail[column] = value;
    },
    save(detail, record) {
      orderDetailSend(detail).then(res => {
        if (res.success) {
          if (res.data) {
            record.status = res.data;
          }
          detail.editable = false;
          this.originalData = "";
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 一键发货
    saveFast(record) {
      orderSendOne(record.id).then(res => {
        if (res.success) {
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      });
      /*    record.details.forEach((item) => {
        orderDetailSend(item).then((res) => {
          if (res.success) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      }); */
    },
    toExcel(record) {
      let tHeader = [
        "订单号",
        "订单ID",
        "姓名",
        "手机号",
        "省",
        "市",
        "县",
        "地址详情",
        "快递公司",
        "快递编号",
        "发货时间",
        "商品名称",
        "平台"
      ];
      // 上面设置Excel的表格第一行的标题
      let filterVal = [
        "id",
        "orderNo",
        "name",
        "mobile",
        "province",
        "city",
        "county",
        "address",
        "waybillCompany",
        "waybillNo",
        "deliveryTimeText",
        "goodName",
        "platformType"
      ];
      // 第二种
      if (this.downloadType.selected === "2") {
        tHeader = [
          "订单号",
          "订单ID",
          "姓名",
          "手机号",
          "地址",
          "快递公司",
          "快递编号",
          "发货时间",
          "商品名称",
          "平台"
        ];
        // 上面设置Excel的表格第一行的标题
        filterVal = [
          "id",
          "orderNo",
          "name",
          "mobile",
          "addressDetail",
          "waybillCompany",
          "waybillNo",
          "deliveryTimeText",
          "goodName",
          "platformType"
        ];
      }
      const list = record.details; // 把data里的tableData存到list
      const goodName = record.goods.name;
      list.forEach(it => {
        it.addressDetail = it.province + it.city + it.county + it.address;
        it.goodName = goodName;
        it.platformType = platformType[it.platformType];
        it.deliveryTimeText = formatDate(it.deliveryTime);
      });
      const data = list.map(v => filterVal.map(j => v[j]));
      export_json_to_excel(tHeader, data, record.no + "订单明细");
    },
    cancel(detail) {
      const original = JSON.parse(this.originalData);
      detail.waybillCompany = original.waybillCompany;
      detail.waybillNo = original.waybillNo;
      detail.editable = false;
      this.originalData = "";
    },
    customRequest(fileData) {
      const that = this;
      const { $message, $refs } = this;
      that.upload = true;
      // 上传并解析
      const types = fileData.file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
        item => item === types
      );
      if (!fileType) {
        // 上传的不是excel
        $message.error("格式错误！请重新选择");
        that.upload = false;
        return fileData.onError(); // 拒绝上传操作
      }
      // 处理excel
      this.file2Xce(fileData.file)
        .then(tabJson => {
          if (tabJson && tabJson.length > 0) {
            const details = [];
            const titleArray = ["订单号", "快递编号", "快递公司"];
            tabJson[0].sheet.forEach((item, index, arr) => {
              details.push({
                id: item[titleArray[0]],
                waybillNo: item[titleArray[1]]
                  ? item[titleArray[1]]
                  : item["运单号"],
                waybillCompany: item[titleArray[2]]
              });
            });
            const errorDetailSend = [];
            let sendSize = 0;
            for (let i = 0; i < details.length; i += 100) {
              sendSize++;
              // resultSend.push()
              orderDetailSendMany(details.slice(i, i + 100))
                .then(res => {
                  try {
                    if (res.success) {
                      $message.success("新增保存失败" + res.data.length + "条");
                      Array.prototype.push.apply(errorDetailSend, res.data);
                    } else {
                      console.info("res", res);
                    }
                  } catch (e) {
                    console.info("e", e);
                  }
                })
                .finally(() => {
                  sendSize--;
                  if (sendSize === 0) {
                    // 判断全部成功的状态
                    if (errorDetailSend.length > 0) {
                      // 下载失败的
                      export_json_to_excel(
                        ["订单号", "快递编号", "快递公司"],
                        errorDetailSend.map(v =>
                          ["id", "waybillNo", "waybillCompany"].map(j => v[j])
                        ),
                        sysTime() + "发货失败" + errorDetailSend.length + "条"
                      );
                    }
                    that.upload = false;
                    alert(
                      "保存成功" +
                        (details.length - errorDetailSend.length) +
                        "条, 失败" +
                        errorDetailSend.length +
                        "条"
                    );
                    return fileData.onSuccess(); // 上传成功了
                  }
                });
            }
          }
        })
        .catch(error => {
          $message.error("上传异常");
          that.upload = false;
          return fileData.onError(); // 拒绝上传操作
        });
    },
    file2Xce(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary"
          });
          const result = [];
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            });
          });
          resolve(result);
        };
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file); // 传统input方法
      });
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.payTimeStart;
        delete this.queryParam.payTimeEnd;
      } else {
        this.queryParam.payTimeStart = Date.parse(new Date(dateString[0]));
        this.queryParam.payTimeEnd = Date.parse(new Date(dateString[1]));
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;
      const today = new Date(new Date().toLocaleDateString()).getTime();
      let payTimeEnd = today + 24 * 60 * 60 * 1000;
      const payTimeStart = today - 24 * 60 * 60 * 1000 * record.date;
      if (record.date === 1) payTimeEnd = today;
      this.queryParam.payTimeStart = payTimeStart;
      this.queryParam.payTimeEnd = payTimeEnd;
      this.$refs.table.refresh(false);
    },
    downloadAll() {
      const localDataSource = this.$refs.table.localDataSource;
      const list = []; // details
      localDataSource.map(dataScource => {
        if (
          dataScource.status === "IN" &&
          dataScource.details &&
          dataScource.details.length > 0
        ) {
          const goodName = dataScource.goods.name;
          dataScource.details.map(it => {
            if (!it.waybillCompany || !it.waybillNo) {
              it.platformType = platformType[it.platformType];
              it.goodName = goodName;
              list.push(it);
            }
          });
        }
      });
      // console.log('一键下载', data)
      let tHeader = [
        "订单号",
        "订单ID",
        "姓名",
        "手机号",
        "省",
        "市",
        "县",
        "地址详情",
        "快递公司",
        "快递编号",
        "发货时间",
        "商品名称",
        "平台"
      ];
      // 上面设置Excel的表格第一行的标题
      let filterVal = [
        "id",
        "orderNo",
        "name",
        "mobile",
        "province",
        "city",
        "county",
        "address",
        "waybillCompany",
        "waybillNo",
        "deliveryTimeText",
        "goodName",
        "platformType"
      ];
      // 第二种
      if (this.downloadType.selected === "2") {
        tHeader = [
          "订单号",
          "订单ID",
          "姓名",
          "手机号",
          "地址",
          "快递公司",
          "快递编号",
          "发货时间",
          "商品名称",
          "平台"
        ];
        // 上面设置Excel的表格第一行的标题
        filterVal = [
          "id",
          "orderNo",
          "name",
          "mobile",
          "addressDetail",
          "waybillCompany",
          "waybillNo",
          "deliveryTimeText",
          "goodName",
          "platformType"
        ];
      }
      // const list = record.details;  //把data里的tableData存到list
      list.forEach(it => {
        it.addressDetail = it.province + it.city + it.county + it.address;
        it.deliveryTimeText = formatDate(it.deliveryTime);
      });
      const data = list.map(v => filterVal.map(j => v[j]));
      export_json_to_excel(tHeader, data, sysTime() + "查询页进行中的订单明细");
    },
    // 下载所有自己出售的子订单
    // 支付时间
    loadAllOrder() {
      // 86400000
      // const timeStamp =
      //   parseInt(this.queryParam.payTimeEnd) -
      //   parseInt(this.queryParam.payTimeStart);
      // if (timeStamp > 86400000) {
      //   return this.$message.error("仅支持下载一天的订单");
      // }
      const data = {
        payTimeStart: this.queryParam.payTimeStart,
        payTimeEnd: this.queryParam.payTimeEnd,
        goodsId: this.queryParam.goodsId
      };
      orderAllDetail(2, data).then(res => {
        if (res.success) {
          // 下载excel文件
          const tHeader = ["快递编号"];
          const filterVal = ["waybillNo"];
          const data = res.data.map(v => filterVal.map(j => v[j]));
          export_json_to_excel(
            tHeader,
            data,
            sysTime() + "查询所有出售的子订单"
          );
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 发货时间
    loadAllOrderDelivery() {
      // deliveryTimeStart deliveryTimeEnd
      // 86400000
      // const timeStamp =
      //   parseInt(this.queryParam.payTimeEnd) -
      //   parseInt(this.queryParam.payTimeStart);
      // if (timeStamp > 86400000) {
      //   return this.$message.error("仅支持下载一天的订单");
      // }
      const data = {
        deliveryTimeStart: this.queryParam.payTimeStart,
        deliveryTimeEnd: this.queryParam.payTimeEnd,
        goodsId: this.queryParam.goodsId
      };
      orderAllDetail(2, data).then(res => {
        if (res.success) {
          // 下载excel文件
          const tHeader = ["快递编号"];
          const filterVal = ["waybillNo"];
          const data = res.data.map(v => filterVal.map(j => v[j]));
          export_json_to_excel(
            tHeader,
            data,
            sysTime() + "查询所有出售的子订单"
          );
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.input_group {
  display: flex;
  margin-bottom: 12px;
  span {
    margin-top: 5px;
    width: 88px;
    text-align: right;
  }
}
.modify_input {
  width: 560px;
}
.top5 {
  margin-bottom: 8px;
}
.time-select {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-left: 8px;
  li {
    list-style: none;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
  }
  li.active {
    color: #1890ff;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
</style>
